import { Middleware } from '@nuxt/types';

const middleware: Middleware = async (context) => {
  if (!context.app.$vsf.$magento.config.state.getCustomerToken() ) {
    context.redirect(context.app.localePath('/connexion'));
  } else {
    const { data } = await context.app.$vsf.$magento.api.customer({ customer: 'customer' });
    if (data?.customer?.is_approved !== 'approved') {
      context.redirect(context.app.localePath('/compte-en-attente'));
    }
  }
};
export default middleware;
